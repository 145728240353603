import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.scss";

import HomePage from "./components/pages/HomePage";
import HistoryPage from "./components/pages/HistoryPage";
import GalleryPage from "./components/pages/GallleryPage";
import CreditsPage from "./components/pages/CreditsPage";
import InfoGraphPage from "./components/pages/InfoGraphPage";
import Blogs from "./components/pages/blogs/blogs";
import Events from "./components/pages/events/events";
import ScrollToTop from "./common/ScrollToTop";
import NotFoundPage from "./components/pages/NotFoundPage";
import BlogPage from "./components/pages/blogs/blog";
import EventPage from "./components/pages/events/event";
import GlossaryPage from "./components/pages/glossary/GlossaryPage";

function App() {
	return (
		<Router>
			<ScrollToTop />

			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/history" element={<HistoryPage />} />
				<Route path="/gallery" element={<GalleryPage />} />
				<Route path="/glossary" element={<GlossaryPage />} />
				<Route path="/credits" element={<CreditsPage />} />
				<Route path="/timeline" element={<InfoGraphPage />} />
				<Route path="/blogs" element={<Blogs />} />
				<Route path="/blogs/:id" element={<BlogPage />} />
				<Route path="/events" element={<Events />} />
				<Route path="/events/:id" element={<EventPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</Router>
	);
}

export default App;
